import { IGlobalState, store } from 'store/store';
import MikeVisualizerViewManager from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/MikeVisualizerViewManager';

import { isEmpty } from 'lodash-es';
import { ELEMENT_CATEGORIES, EElementCategories } from 'src/shared/panels/mesh-panel-constants';

import { IDrawnDataItem, IWorkspaceEnrichedDataItem } from 'models/IWorkspaceData';
import { IWorkspaceEnrichedGeometry } from 'models/IGeometries';
import { IWorkspaceEnrichedMesh } from 'models/IMeshes';
import { IWorkspaceEnrichedVariable } from 'models/IVariables';
import { OPERATION_STATES, IOperationMetadata } from 'models/IOperations';
import GeometryIconUtils from 'src/geometries/geometry-icon-utils';
import VariableIconUtils from 'src/variables/variable-icon-utils';
import MeshIconUtils from 'src/meshes/mesh-icon-utils';

import WorkspaceUtils from 'src/workspaces/workspace-utils';
import { ROUTES } from 'src/app/routes';
import { isOperationOutdated } from 'store/selectors/WorkspaceOperationUtils';
import WorkspaceSocketManager from 'src/managers/WorkspaceSocketManager';
import WorkspaceGeometrySelectors from 'src/store/selectors/WorkspaceGeometrySelectors';
import { IWorkspaceQuery } from 'src/models/IQueries';
import { EWorkspaceQueryActionType } from 'src/store/actions/WorkspaceQueryActionType';
import WorkspaceMeshSelectors from 'src/store/selectors/WorkspaceMeshSelectors';
import { EWorkspaceGeometryActionType } from 'src/store/actions/WorkspaceGeometryActionType';
import { EWorkspaceMeshActionType } from 'src/store/actions/WorkspaceMeshActionType';
import { EWorkspaceVariableActionType } from 'src/store/actions/WorkspaceVariableActionType';
import { IBounds } from 'src/models/IWorkspaceNotifications';

const { routeToPanel } = WorkspaceUtils;

/**
 * Shows a layer.
 *
 * @param type The type of layer (geometry, variable, mesh)
 * @param layerId The id of the item
 * @param updateUserSettings If true the items actively hidden by the user will be updated as well
 */
export const showLayer = (type: EElementCategories, layerId: string, updateUserSettings = true) => {
  switch (type) {
    case ELEMENT_CATEGORIES.GEOMETRY:
      return store.dispatch({
        type: EWorkspaceGeometryActionType.SHOW_ITEM,
        workspaceGeometryId: layerId,
        updateUserSettings,
      });

    case ELEMENT_CATEGORIES.VARIABLE:
      return store.dispatch({
        type: EWorkspaceVariableActionType.SHOW_ITEM,
        workspaceVariableId: layerId,
        updateUserSettings,
      });

    case ELEMENT_CATEGORIES.MESH:
      return store.dispatch({
        type: EWorkspaceMeshActionType.SHOW_ITEM,
        workspaceMeshId: layerId,
        updateUserSettings,
      });

    default:
      return false;
  }
};

/**
 * Hides a layer.
 *
 * @param type The type of layer (geometry, variable, mesh)
 * @param layerId The id of the item
 * @param updateUserSettings If true the items actively hidden by the user will be updated as well
 */
export const hideLayer = (type: EElementCategories, layerId: string, updateUserSettings = true) => {
  switch (type) {
    case ELEMENT_CATEGORIES.GEOMETRY:
      return store.dispatch({
        type: EWorkspaceGeometryActionType.HIDE_ITEM,
        workspaceGeometryId: layerId,
        updateUserSettings,
      });

    case ELEMENT_CATEGORIES.VARIABLE:
      return store.dispatch({
        type: EWorkspaceVariableActionType.HIDE_ITEM,
        workspaceVariableId: layerId,
        updateUserSettings,
      });

    case ELEMENT_CATEGORIES.MESH:
      return store.dispatch({
        type: EWorkspaceMeshActionType.HIDE_ITEM,
        workspaceMeshId: layerId,
        updateUserSettings,
      });

    default:
      return false;
  }
};

/**
 * Hide all other layers that the layer given by layerId;
 *
 * @param type
 * @param layerId
 * @param updateUserSettings If true the items actively hidden by the user will be updated as well
 */
export const hideOtherLayers = (type: EElementCategories, layerId: string, updateUserSettings = false) => {
  switch (type) {
    case ELEMENT_CATEGORIES.GEOMETRY: {
      store.dispatch({ type: EWorkspaceMeshActionType.HIDE_ALL });
      store.dispatch({ type: EWorkspaceVariableActionType.HIDE_ALL });
      hideOtherGeometries(layerId, updateUserSettings);
      break;
    }

    case ELEMENT_CATEGORIES.VARIABLE: {
      store.dispatch({ type: EWorkspaceGeometryActionType.HIDE_ALL });
      store.dispatch({ type: EWorkspaceMeshActionType.HIDE_ALL });
      hideOtherVariables(layerId, updateUserSettings);
      break;
    }

    case ELEMENT_CATEGORIES.MESH: {
      store.dispatch({ type: EWorkspaceGeometryActionType.HIDE_ALL });
      store.dispatch({ type: EWorkspaceVariableActionType.HIDE_ALL });
      hideOtherMeshes(layerId, updateUserSettings);
      break;
    }

    default:
      break;
  }

  store.dispatch({
    type: 'workspace/element/CLEAR_HIGHLIGHT',
  });
};

export const hideOtherMeshes = (meshId: string, updateUserSettings = false) => {
  store.dispatch({
    type: EWorkspaceMeshActionType.SHOW_ITEM_ONLY,
    meshId,
    updateUserSettings,
  });
};

export const hideOtherGeometries = (geometryId: string, updateUserSettings = false) => {
  store.dispatch({
    type: EWorkspaceGeometryActionType.SHOW_ITEM_ONLY,
    geometryId,
    updateUserSettings,
  });
};

export const hideOtherVariables = (variableId: string, updateUserSettings = false) => {
  store.dispatch({
    type: EWorkspaceVariableActionType.SHOW_ITEM_ONLY,
    variableId,
    updateUserSettings,
  });
};

/**
 * Mark a layer as highlighted. There can only be 1 highligthed layer at a time.
 *
 * @param layerId
 */
export const highlightLayer = (layerId: string) => {
  store.dispatch({
    type: 'workspace/element/HIGHLIGHT',
    highlightedWorkspaceElementId: layerId,
  });
};

/**
 * Checks if a layer is doing any 'work'. Either because it is in progress or because it is still loading.
 *
 * @param layer
 * @param drawnItems List of drawn items. Can be all drawn items or a subset based on layer type.
 * @param idsWithLoadingTiles
 */
export const isLayerWorking = (
  layer: IWorkspaceEnrichedGeometry | IWorkspaceEnrichedMesh | IWorkspaceEnrichedVariable,
  drawnItems: Array<IDrawnDataItem>,
  idsWithLoadingTiles?: Array<string>,
): boolean => {
  return self.isLayerInProgress(layer) || self.isLayerLoading(layer, drawnItems, idsWithLoadingTiles);
};

/**
 * Checks if a layer is in progress based on the state . It can be 'processing' or any sort of other async work, like 'scheduled'.
 * This does NOT include if the layer is loading. To do that, you should use @use isLayerLoading instead.
 *
 * @param layer
 */
export const isLayerInProgress = (
  layer: IWorkspaceEnrichedGeometry | IWorkspaceEnrichedMesh | IWorkspaceEnrichedVariable | IOperationMetadata,
): boolean => {
  return self.isLayerProcessing(layer) || self.isLayerScheduled(layer);
};

/**
 * Checks if a layer is loading by looking if a drawn item matching its id exists. It's safe to assume what isn't drawn is not yet loaded.
 *
 * @param layer
 * @param drawnItems List of drawn items. Can be all drawn items or a subset based on layer type.
 * @param idsWithLoadingTiles
 */
export const isLayerLoading = (
  layer: IWorkspaceEnrichedGeometry | IWorkspaceEnrichedMesh | IWorkspaceEnrichedVariable,
  drawnItems: Array<IDrawnDataItem>,
  idsWithLoadingTiles?: Array<string>,
): boolean => {
  if (isEmpty(layer)) {
    return false;
  }

  if (!layer.dataId) {
    return false;
  }

  if (self.isLayerFailed(layer)) {
    return false;
  }
  if (layer.isTiled && idsWithLoadingTiles) {
    return idsWithLoadingTiles.includes(layer.id);
  } else {
    const drawnIds = (drawnItems || []).map(({ id }) => id);
    const drawnDataIds = (drawnItems || []).map(({ dataId }) => dataId);
    return drawnIds.indexOf(layer.id) === -1 && drawnDataIds.indexOf(layer.dataId) === -1;
  }
};

/**
 * Checks if a layer is scheduled.
 *
 * @param layer
 */
export const isLayerScheduled = (
  layer: IWorkspaceEnrichedGeometry | IWorkspaceEnrichedMesh | IWorkspaceEnrichedVariable | IOperationMetadata,
): boolean => {
  return !isEmpty(layer) && layer.state === OPERATION_STATES.SCHEDULED;
};

/**
 * Checks if a layer is processing.
 *
 * @param layer
 */
export const isLayerProcessing = (
  layer: IWorkspaceEnrichedGeometry | IWorkspaceEnrichedMesh | IWorkspaceEnrichedVariable | IOperationMetadata,
) => {
  return !isEmpty(layer) && layer.state === OPERATION_STATES.PROCESSING;
};

/**
 * Checks if a layer is failed.
 *
 * @param layer
 */
export const isLayerFailed = (
  layer: IWorkspaceEnrichedGeometry | IWorkspaceEnrichedMesh | IWorkspaceEnrichedVariable | IOperationMetadata,
): boolean => {
  return !isEmpty(layer) && layer.state === OPERATION_STATES.FAILED;
};

export const showErrorMessageOnOperationFailed = (operationMetadata: IOperationMetadata) => {
  if (isLayerFailed(operationMetadata)) {
    const errorMessage = operationMetadata.latestMessage ? operationMetadata.latestMessage.message : '';
    if (errorMessage) {
      const toast = {
        text: errorMessage,
        operationId: operationMetadata.id,
      };
      store.dispatch({ type: 'toast/ADD/ERROR', toast });
    }
  }
};

export const fetchLayer = (
  layerId: string,
  elementCategory?: EElementCategories,
  isInterpolation?: boolean,
  updateUserSettings?: boolean,
) => {
  const socketManager = WorkspaceSocketManager.getInstance();
  const { streamVtkFileData } = socketManager;
  streamVtkFileData(layerId);
  if (elementCategory && !isInterpolation) {
    showLayer(elementCategory, layerId, updateUserSettings ? updateUserSettings : true);
  }
};

export const fetchTiledLayer = (
  layerId: string,
  previousBounds: Array<IBounds>,
  bounds: Array<Array<number>>,
  ignoreOverviewPolygon: boolean,
) => {
  const socketManager = WorkspaceSocketManager.getInstance();
  const { streamMesh } = socketManager;
  streamMesh(layerId, bounds, previousBounds, ignoreOverviewPolygon);
  showLayer(EElementCategories.MESH, layerId, true);
};

/**
 * Checks if a layer is conflicting.
 * @see EOperationStates for a description of conflicting operations.
 *
 * @param layer
 */
export const isLayerConflicting = (
  layer: IWorkspaceEnrichedGeometry | IWorkspaceEnrichedMesh | IWorkspaceEnrichedVariable | IOperationMetadata,
) => {
  return !isEmpty(layer) && layer.state === OPERATION_STATES.CONFLICTING;
};

/**
 * Checks if a layer is outdated.
 * @see EOperationStates for a description of outdated operations.
 *
 * @param layer
 */
export const isLayerOutdated = (
  layer: IWorkspaceEnrichedGeometry | IWorkspaceEnrichedMesh | IWorkspaceEnrichedVariable | IOperationMetadata,
) => {
  return !isEmpty(layer) && isOperationOutdated(layer);
};

/**
 * Checks if any of the layers are in progress based on the state of the layers, @see isLayerInProgress.
 *
 * @param layers
 */
export const isAnyLayerInProgress = (
  layers: Array<IWorkspaceEnrichedGeometry | IWorkspaceEnrichedMesh | IWorkspaceEnrichedVariable | IOperationMetadata>,
): boolean => {
  if (!layers || layers.length === 0) {
    return false;
  }

  return layers.findIndex((layer) => self.isLayerInProgress(layer)) !== -1;
};

/**
 * Checks if any of the layers are loading based on the state of the layers, @see isLayerLoading.
 *
 * @param layers
 * @param drawnItems
 */
export const isAnyLayerLoading = (
  layers: Array<IWorkspaceEnrichedGeometry | IWorkspaceEnrichedMesh | IWorkspaceEnrichedVariable | IOperationMetadata>,
  drawnItems: Array<IDrawnDataItem>,
): boolean => {
  if (!layers || layers.length === 0) {
    return false;
  }

  return layers.findIndex((layer) => self.isLayerLoading(layer, drawnItems)) !== -1;
};

/**
 * Checks if any of the layers are working based on the state of the layers, @see isLayerWorking.
 *
 * @param layers
 * @param drawnItems
 */
export const isAnyLayerWorking = (
  layers: Array<IWorkspaceEnrichedGeometry | IWorkspaceEnrichedMesh | IWorkspaceEnrichedVariable | IOperationMetadata>,
  drawnItems: Array<IDrawnDataItem>,
): boolean => {
  if (!layers || layers.length === 0) {
    return false;
  }

  return isAnyLayerInProgress(layers) || isAnyLayerLoading(layers, drawnItems);
};

/**
 * Checks if a given layer is hidden at this point in time.
 *
 * NB: This might proove to be too expensive in some cases because or state reads for each call.
 * Consider using a container with stateChanged() if you want to call this method for more than initial state or pass in hiddenLayers.
 *
 * @param type
 * @param layerId
 * @param hiddenLayers
 */
export const isLayerHidden = (type: EElementCategories, layerId: string, hiddenLayers?: Array<string>): boolean => {
  switch (type) {
    case ELEMENT_CATEGORIES.GEOMETRY: {
      const hiddenWorkspaceGeometries =
        hiddenLayers || store.getState().WorkspaceGeometryReducer.hiddenWorkspaceGeometries;

      return hiddenWorkspaceGeometries.indexOf(layerId) !== -1;
    }

    case ELEMENT_CATEGORIES.VARIABLE: {
      const hiddenWorkspaceVariables =
        hiddenLayers || store.getState().WorkspaceVariableReducer.hiddenWorkspaceVariables;

      return hiddenWorkspaceVariables.indexOf(layerId) !== -1;
    }

    case ELEMENT_CATEGORIES.MESH: {
      const hiddenWorkspaceMeshes = hiddenLayers || store.getState().WorkspaceMeshReducer.hiddenWorkspaceMeshes;

      return hiddenWorkspaceMeshes.indexOf(layerId) !== -1;
    }

    default:
      return false;
  }
};

/**
 * Sets a layer as active.
 * This effectively 'opens' the layer in its own panel.
 *
 * @param type
 * @param layerId
 * @param workspaceId
 * @param projectId
 */
export const setActiveLayer = (type: EElementCategories, layerId: string, workspaceId: string, projectId: string) => {
  switch (type) {
    case ELEMENT_CATEGORIES.GEOMETRY:
      return self.setActiveGeometry(layerId, workspaceId, projectId);

    case ELEMENT_CATEGORIES.VARIABLE:
      return self.setActiveVariable(layerId, workspaceId, projectId);

    case ELEMENT_CATEGORIES.MESH:
      return self.setActiveMesh(layerId, workspaceId, projectId);

    default:
  }

  return '';
};

/**
 * Sets a geometry as active.
 * This effectively 'opens' the geometry, with the following effects:
 *
 * - Hide all meshes, geometries, variables, then-
 * - Show active geometry
 * - Highlight active geometry
 * - Open geometry details panel
 *
 * @param geometryId
 * @param workspaceId
 * @param projectId
 */
export const setActiveGeometry = (geometryId: string, workspaceId: string, projectId: string) => {
  // Hide selections not related to geometry
  const state: IGlobalState = store.getState();
  const getGeometryQueriesSelectorInstance = WorkspaceGeometrySelectors.makeGetGeometryQueries();
  const geometryQueries: Array<IWorkspaceQuery> = getGeometryQueriesSelectorInstance(state, { geometryId });
  if (geometryQueries) {
    const ids = geometryQueries.map((geom: IWorkspaceQuery) => geom.id);
    store.dispatch({ type: EWorkspaceQueryActionType.OTHER_SELECTIONS_HIDE, ids });
  }

  store.dispatch({
    type: EWorkspaceMeshActionType.HIDE_ALL,
  });

  store.dispatch({
    type: EWorkspaceVariableActionType.HIDE_ALL,
  });

  store.dispatch({
    type: EWorkspaceGeometryActionType.SHOW_ITEM_ONLY,
    geometryId,
    updateUserSettings: true, // remove id from user hidden geometries in order to keep the layer visible when closing details panel
  });

  routeToPanel(ROUTES.geometryDetailsPanel.path, {
    geometryId,
    workspaceId,
    projectId,
  });
};

/**
 * Sets a variable as active.
 * This effectively 'opens' the variable, with the following effects:
 *
 * - Hide all meshes & geometries, then-
 * - Show active variable
 * - Navigate to variable details panel
 *
 * NB: does not hide variables for performance reasons.
 *
 * @param variableId
 * @param workspaceId
 * @param projectId
 */
export const setActiveVariable = (variableId: string, workspaceId: string, projectId: string) => {
  store.dispatch({
    type: EWorkspaceMeshActionType.HIDE_ALL,
  });

  store.dispatch({
    type: EWorkspaceGeometryActionType.HIDE_ALL,
  });

  store.dispatch({
    type: EWorkspaceVariableActionType.SHOW_ITEM_ONLY,
    variableId,
    updateUserSettings: true, // remove id from user hidden variables in order to keep the layer visible when closing details panel
  });

  routeToPanel(ROUTES.variableDetailsPanel.path, {
    variableId,
    workspaceId,
    projectId,
  });
};

/**
 * Sets a mesh as active.
 * This effectively 'opens' the mesh in the panel given, with the following effects:
 *
 * - Hide all meshes, geometries, variables, then-
 * - Show active mesh
 * - Navigate to mesh details panel
 *
 * @param meshId
 * @param workspaceId
 * @param projectId
 */
export const setActiveMesh = (meshId: string, workspaceId: string, projectId: string) => {
  // Hide selections not related to mesh
  const state: IGlobalState = store.getState();
  const getMeshQueriesSelectorInstance = WorkspaceMeshSelectors.makeGetMeshQueries();
  const meshQueries: Array<IWorkspaceQuery> = getMeshQueriesSelectorInstance(state, { meshId });
  if (meshQueries) {
    const ids = meshQueries.map((meshQuery: IWorkspaceQuery) => meshQuery.id);
    store.dispatch({ type: EWorkspaceQueryActionType.OTHER_SELECTIONS_HIDE, ids });
  }

  store.dispatch({
    type: EWorkspaceGeometryActionType.HIDE_ALL,
  });

  store.dispatch({
    type: EWorkspaceVariableActionType.HIDE_ALL,
  });

  store.dispatch({
    type: EWorkspaceMeshActionType.SHOW_ITEM_ONLY,
    meshId,
    updateUserSettings: true, // remove id from user hidden geometries in order to keep the layer visible when closing details panel
  });

  routeToPanel(ROUTES.meshDetailsPanel.path, {
    meshId,
    workspaceId,
    projectId,
  });
};

/**
 * Hides all geometries, variables and meshes.
 */
export const hideAllItems = () => {
  store.dispatch({
    type: EWorkspaceMeshActionType.HIDE_ALL,
  });

  store.dispatch({
    type: EWorkspaceGeometryActionType.HIDE_ALL,
  });

  store.dispatch({
    type: EWorkspaceVariableActionType.HIDE_ALL,
  });
};

/**
 * Selects a layer.
 *
 * @param type
 * @param layerId
 * @param unselectOthers
 */
export const selectLayer = (type: EElementCategories, layerId: string, unselectOthers = false) => {
  switch (type) {
    case ELEMENT_CATEGORIES.GEOMETRY:
      return store.dispatch({
        type: EWorkspaceGeometryActionType.SELECT,
        workspaceGeometryId: layerId,
        unselectOthers,
      });

    case ELEMENT_CATEGORIES.VARIABLE:
      return store.dispatch({
        type: EWorkspaceVariableActionType.SELECT,
        workspaceVariableId: layerId,
        unselectOthers,
      });

    case ELEMENT_CATEGORIES.MESH:
      return store.dispatch({
        type: EWorkspaceMeshActionType.SELECT,
        workspaceMeshId: layerId,
        unselectOthers,
      });

    default:
      return false;
  }
};

/**
 * Deselects a layer.
 *
 * @param type
 * @param layerId
 */
export const deselectLayer = (type: EElementCategories, layerId: string) => {
  switch (type) {
    case ELEMENT_CATEGORIES.GEOMETRY:
      store.dispatch({
        type: 'workspace/geometries/DESELECT',
        workspaceGeometryId: layerId,
      });
      break;

    case ELEMENT_CATEGORIES.VARIABLE:
      store.dispatch({
        type: 'workspace/variables/DESELECT',
        workspaceVariableId: layerId,
      });
      break;

    case ELEMENT_CATEGORIES.MESH:
      store.dispatch({
        type: 'workspace/meshes/DESELECT',
        workspaceMeshId: layerId,
      });
      break;

    default:
  }
};

/**
 * Checks if a given layer is selected at this point in time.
 *
 * NB: This might proove to be too expensive in some cases because or state reads for each call.
 * Consider using a container with stateChanged() if you want to call this method for more than initial state or pass in selectedLayers.
 *
 * @param type
 * @param layerId
 * @param selectedLayers
 */
export const isLayerSelected = (type: EElementCategories, layerId: string, selectedLayers?: Array<string>): boolean => {
  switch (type) {
    case ELEMENT_CATEGORIES.GEOMETRY: {
      const selectedWorkspaceGeometries =
        selectedLayers || store.getState().WorkspaceGeometryReducer.selectedWorkspaceGeometries;

      return selectedWorkspaceGeometries.indexOf(layerId) !== -1;
    }

    case ELEMENT_CATEGORIES.VARIABLE: {
      const selectedWorkspaceVariables =
        selectedLayers || store.getState().WorkspaceVariableReducer.selectedWorkspaceVariables;

      return selectedWorkspaceVariables.indexOf(layerId) !== -1;
    }

    case ELEMENT_CATEGORIES.MESH: {
      const selectedWorkspaceMeshes = selectedLayers || store.getState().WorkspaceMeshReducer.selectedWorkspaceMeshes;

      return selectedWorkspaceMeshes.indexOf(layerId) !== -1;
    }

    default:
      return false;
  }
};

/**
 * Checks if a layer is disabled.
 *
 * @param layerId
 * @param disabledLayers
 */
export const isLayerDisabled = (layerId: string, disabledLayers?: Array<string>): boolean => {
  return (disabledLayers || []).indexOf(layerId) !== -1;
};

/**
 * Checks if a mesh generate action is available.
 *
 * @param mesh
 */
const isMeshGenerateAvailable = (mesh: IWorkspaceEnrichedMesh) => {
  return !self.isMeshGenerated(mesh) && !self.isLayerInProgress(mesh);
};

/**
 * Check if the mesh is currently generated
 * @param mesh
 */
export const isMeshGenerated = (mesh: IWorkspaceEnrichedMesh) => {
  return !isEmpty(mesh) && Boolean(mesh.dataId) && mesh.state !== OPERATION_STATES.DRAFT;
};
/**
 * Checks if a mesh interpolation action is available.
 * todo hevo Maybe this is relevant to both interpolations and post-operations (and even create mesh ?)- if so should be renamed to isMeshOperationsAllowed and used in both places
 * @param mesh
 */
export const isMeshInterpolationAllowed = (mesh: IWorkspaceEnrichedMesh) => {
  return self.isMeshGenerated(mesh) && !self.isLayerInProgress(mesh);
};

/**
 * Get a layer's surface color.
 *
 * @param layer
 * @param drawnItems
 */
export const getLayerSurfaceColor = (
  layer: IWorkspaceEnrichedGeometry | IWorkspaceEnrichedMesh | IWorkspaceEnrichedVariable,
  drawnItems: Array<IDrawnDataItem>,
) => {
  const drawnItem = drawnItems.find(({ id }) => id === layer.id);

  if (drawnItem) {
    return drawnItem.surfaceColor;
  }

  return null;
};

/**
 * Get a layer's edge color.
 *
 * @param layer
 * @param drawnItems
 */
export const getLayerEdgeColor = (
  layer: IWorkspaceEnrichedGeometry | IWorkspaceEnrichedMesh | IWorkspaceEnrichedVariable,
  drawnItems: Array<IDrawnDataItem>,
) => {
  const drawnItem = drawnItems.find(({ id }) => id === layer.id);

  if (drawnItem) {
    return drawnItem.edgeColor;
  }

  return null;
};

export const getLayerIcon = (layer: IWorkspaceEnrichedDataItem): React.ReactNode => {
  if (!layer) {
    return null;
  }

  switch (layer.category) {
    case ELEMENT_CATEGORIES.GEOMETRY:
      return GeometryIconUtils.getIcon(layer);
    case ELEMENT_CATEGORIES.VARIABLE:
      return VariableIconUtils.getIcon(layer);
    case ELEMENT_CATEGORIES.MESH:
      return MeshIconUtils.getIcon(layer);
    default: {
      console.debug(`No icon found for ${layer.category} `);
      return null;
    }
  }
};

const self = {
  showLayer,
  hideLayer,
  highlightLayer,
  isLayerWorking,
  isLayerLoading,
  isLayerInProgress,
  isLayerProcessing,
  isLayerScheduled,
  isLayerFailed,
  isLayerConflicting,
  isAnyLayerInProgress,
  isAnyLayerLoading,
  isAnyLayerWorking,
  isLayerHidden,
  setActiveLayer,
  setActiveGeometry,
  setActiveVariable,
  setActiveMesh,
  hideAllItems,
  hideOtherLayers,
  hideOtherMeshes,
  deselectLayer,
  selectLayer,
  isLayerSelected,
  isLayerDisabled,
  isMeshGenerateAvailable,
  isMeshInterpolationAllowed,
  isMeshGenerated,
  getLayerSurfaceColor,
  getLayerEdgeColor,
  fetchLayer,
  getLayerIcon,
};

export default self;
